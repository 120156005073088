import Vue from 'vue';
import Toasted from "vue-toasted";


Vue.use(Toasted, {
    duration: 5000,
    position: 'top-center',
    iconPack: 'mdi',
    action: {
        text: 'OK',
        onClick: (e, toastObject) => {
            toastObject.goAway(0);
        }
    },
});

Vue.toasted.register(
    'success', (payload) => {

        if (!payload.message) {
            return "La operación fué realizada con éxito"
        }
        return payload.message;
    },
    {

        type: 'success',
        icon: 'check',
    })


Vue.toasted.register(
    'error', (payload) => {

        if (!payload.message) {
            return "Se produjo un error, la operación no se completó"
        }
        return payload.message;
    },
    {

        type: 'error',
        icon: 'alert',
    })

Vue.toasted.register(
    'info', (payload) => {

        if (!payload.message) {
            return "La operación fué realizada con éxito"
        }
        return payload.message;

    },
    {
        icon: 'information-outline',
        className: ['light-blue',]
    })
