import axios from "axios";


const TOKEN_ENDPOINT = process.env.VUE_APP_TOKEN_URL;

class AuthService {
  login(user) {
    return axios
      .post(TOKEN_ENDPOINT, {
        username: user.username,
        password: user.password,
      })
      .then(this.handleResponse)
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("token", JSON.stringify(response.data.token));
          localStorage.setItem("user", JSON.stringify(response.data.user));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }

  handleResponse(response) {
    if (response.status === 401) {
      this.logout();
      location.reload();

      const error = response.data && response.data.message;

      return Promise.reject(error);
    }

    return Promise.resolve(response);
  }
}

export default new AuthService();
