import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth";
// import { settings } from "./settings";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawerState: false
  },
  mutations: {
    toggleDrawerState(state, data) {
      state.drawerState = data;
    }
  },
  getters: {
    drawerState: state => state.drawerState
  },
  actions: {},
  modules: {
    auth,
    // settings
  }
});
