import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import moment from "moment";
import VueCurrencyFilter from "vue-currency-filter";



require('./plugins/fontawesome');
require('./plugins/vuetoasted');


Vue.config.productionTip = false

// filters
Vue.use(VueCurrencyFilter, {
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: true
});

Vue.filter("date", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DD HH:mm");
  }
});

Vue.filter("time", function (value) {
  if (value) {
    return moment(String(value)).format("HH:mm");
  }
});

Vue.filter("datef", function (value) {
  if (value) {
    return moment(String(value)).format("DD-MM-YYYY");
  }
});

Vue.filter('currency', function (value) {
  if (value) {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 4
    };
    if (!value) {
      return "0.00"
    }
    return Number(value).toLocaleString('en', options);
  }
})


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')