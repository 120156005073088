import Vue from 'vue';
import {
	library
} from '@fortawesome/fontawesome-svg-core';

import {
	FontAwesomeIcon
} from '@fortawesome/vue-fontawesome';

import {
	faHome,
	faFileExcel,
	faFileInvoice,
	faBan,
	faMoneyCheckAlt,
	faCalculator,
	faClipboardCheck,
	faArchive,
	faPlus,
	faCartArrowDown,
	faFileAlt,
	faReceipt,
	faClipboardList,
	faTruckMoving,
	faGasPump,
	faTasks,
	faShuttleVan,
	faCreditCard,
	faCheckDouble,
	faMoneyCheck,
	faBuilding,
	faSitemap,
} from '@fortawesome/free-solid-svg-icons';

library.add(faHome);
library.add(faFileExcel);
library.add(faFileInvoice);
library.add(faBan);
library.add(faMoneyCheckAlt);
library.add(faCalculator);
library.add(faClipboardCheck);
library.add(faArchive);
library.add(faPlus);
library.add(faCartArrowDown);
library.add(faFileAlt);
library.add(faReceipt);
library.add(faClipboardList);
library.add(faTruckMoving);
library.add(faGasPump);
library.add(faTasks);
library.add(faShuttleVan);
library.add(faCreditCard);
library.add(faCheckDouble);
library.add(faMoneyCheck);
library.add(faBuilding);
library.add(faSitemap);

Vue.component('font-awesome-icon', FontAwesomeIcon);