import AuthService from "@/services/auth";

const token = JSON.parse(localStorage.getItem("token"));
const user = JSON.parse(localStorage.getItem("user"));

const initialState = token
  ? { status: { loggedIn: true }, token, user}
  : { status: { loggedIn: false }, token: null, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        data => {
          commit("loginSuccess", data.token);
          commit("getUser", data.user);

          return Promise.resolve(token);
        },
        error => {
          commit("loginFailure");
          return Promise.reject(error.response.data);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    }
  },
  mutations: {
    getUser(state, user) {
      state.user = user;
    },
    loginSuccess(state, token) {
      state.status = { loggedIn: true };
      state.token = token;
    },
    loginFailure(state) {
      state.status = { loggedIn: false };
      state.token = null;
    },
    logout(state) {
      state.status = { loggedIn: false };
      state.token = null;
      state.userData = null;
    }
  },
  getters: {
    userState: state => state.user,
  },
};
