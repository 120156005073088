import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [{
	path: '/',
	component: () => import('@/views/Index'),
	redirect: '/home',
	meta: {
		requiresAuth: true
	},
	children: [{
		path: '/home',
		name: 'Home',
		component: () => import('@/views/Home')
	}]
},
{
	path: '/reports',
	redirect: '/reports/home',
	component: () => import('@/views/Index'),
	meta: {
		requiresAuth: true
	},
	children: [{
		path: 'home',
		name: 'HomeReports',
		component: () => import('@/views/reports/Home')
	}]
},
{
	path: '/dtes',
	redirect: '/dtes/home/',
	component: () => import('@/views/Index'),
	meta: {
		requiresAuth: true
	},
	children: [{
		path: 'home',
		name: 'HomeDtes',
		component: () => import('@/views/dtes/Home')
	},
	{
		path: 'list',
		name: 'ListDtes',
		component: () => import('@/views/dtes/List')
	},
	{
		path: 'annulments',
		name: 'AnullmentDtes',
		component: () => import('@/views/dtes/Annulments')
	}
	]
},
{
	path: '/accounts',
	redirect: '/accounts/home',
	component: () => import('@/views/Index'),
	meta: {
		requiresAuth: true
	},
	children: [
		{
			path: 'movement-all',
			name: 'accounts-movement-all',
			component: () => import('@/views/accounts/movements/Movements')
		},
	]
},
{
	path: '/accountings',
	redirect: '/accountings/register-buy',
	component: () => import('@/views/Index'),
	meta: {
		requiresAuth: true
	},
	children: [{
		path: 'home',
		name: 'accounting-register-buy-home',
		component: () => import('@/views/accounting/Home')
	},
	{
		path: 'register-buy',
		name: 'accounting-register-buy',
		component: () => import('@/views/accounting/RegisterBuy')
	},
	{
		path: 'provider_list',
		name: 'accounting-provider_list',
		component: () => import('@/views/accounting/ListProviders')
	},
	{
		path: 'provider_create',
		name: 'accounting-provider_create',
		component: () => import('@/views/accounting/CreateProvider')
	},
	{
		path: 'payment_order_create/:o?',
		name: 'accounting-payment_create',
		component: () => import('@/views/accounting/PaymentOrder')
	},
	{
		path: 'list-payment-order',
		name: 'accounting-payment-list',
		component: () => import('@/views/accounting/ListPayment')
	},
	{
		path: 'list-cash-receipt',
		name: 'accounting-cash-receipt-list',
		component: () => import('@/views/accounting/ListReceipt')
	}, 
	{
		path: 'list-invoices',
		name: 'accounting-invoices-list',
		component: () => import('@/views/accounting/invoices/ListInvoice')
	},
	{
		path: 'classifications',
		name: 'classification-list',
		component: () => import('@/views/accounting/account/AccountList')
	}
	]
},
{
	path: '/vouchers',
	redirect: '/vouchers/voucher-lists',
	component: () => import('@/views/Index'),
	meta: {
		requiresAuth: true
	},
	children: [{
		path: 'home',
		name: 'voucher-home',
		component: () => import('@/views/vouchers/Home')
	},
	{
		path: 'voucher-lists',
		name: 'voucher-list',
		component: () => import('@/views/vouchers/List')
	},
	{
		path: 'voucher-create',
		name: 'voucher-create',
		component: () => import('@/views/vouchers/Create')
	},
	]
},
{
	path: '/fleets',
	redirect: '/fleets/home',
	component: () => import('@/views/Index'),
	meta: {
		requiresAuth: true
	},
	children: [{
		path: 'home',
		name: 'fleet-home',
		component: () => import('@/views/fleets/Home')
	},
	{
		path: 'diesel-list',
		name: 'diesel-list',
		component: () => import('@/views/fleets/List')
	},
	{
		path: 'dispatch-diesel-create',
		name: 'dispatch-diesel-create',
		component: () => import('@/views/fleets/Create')
	},
	{
		path: 'tank-create',
		name: 'tank-create',
		component: () => import('@/views/fleets/tank/Create')
	},
	{
		path: 'vehicle-list',
		name: 'vehicle-list',
		component: () => import('@/views/fleets/vehicle/ListVehicle')
	},
	]
},
{
	path: '/auth',
	redirect: 'auth/login',
	name: 'Auth',
	component: {
		render(c) {
			return c('router-view');
		}
	},
	children: [{
		path: 'login',
		name: 'Login',
		component: () => import('@/views/auth/Login'),
		meta: {}
	}]
},
{
	path: '*',
	redirect: '/404',
	component: {
		component: () => import('@/views/pages/Page404')
	}
},
{
	path: '/projects',
	redirect: '/projects/all',
	component: () => import('@/views/Index'),
	meta: {
		requiresAuth: true
	},
	children: [{
		path: 'all',
		name: 'project-list-all',
		component: () => import('@/views/projects/ListProyect')
	},
	]
},
{
	path: '/dealings',
	redirect: '/dealings/all',
	component: () => import('@/views/Index'),
	meta: {
		requiresAuth: true
	},
	children: [
		{
		path: 'all',
		name: 'dealing-list-all',
		component: () => import('@/views/dealings/List')
	},
	]
},
];

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	document.title = to.meta.title || process.env.VUE_APP_NAME || 'Welcome';
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!store.state.auth.status.loggedIn) {
			router
				.push({
					path: '/auth/login'
				})
				.catch(() => { });
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;